import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import { serverPath } from "../../config/key";
import InfoModal from "../common/infoModal";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";

const RxContactForm = () => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  // inital state
  const contactInit = {
    hebName: "",
    enName: "",
    areaOfExpertise: "",
    licenseNumber: "",
    idNumber: "",
    phone: "",
    email: "",
    sourceOfDiscovery: "",
  };

  // States
  const [userGeoData, setUserGeoData] = useState({});
  const [fieldState, setFieldState] = useState(contactInit);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState(fieldState);
  const [show, setShow] = useState(false);
  const [formStatus, setFormStatus] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  useEffect(() => {
    if (!loader) {
      setRefreshReCaptcha(false);
    }
  }, [loader]);

  const handleIsCheck = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    setFieldState({ ...fieldState, madanesConsent: newCheckedState });
    setErrors({ ...errors, madanesConsent: "" });
  };

  // utils function
  const handleShow = () => setShow(!show);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFieldState({ ...fieldState, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const handleChangePhone = (value) => {
    setFieldState({ ...fieldState, phone: value });
    setErrors({ ...errors, phone: "" });
  };

  const validateContactField = () => {
    let valid = true;
    let error = {};
    if (fieldState.hebName.trim() === "") {
      error.hebName = t("info.firstName");
      valid = false;
    }
    if (fieldState.enName.trim() === "") {
      error.enName = t("info.lastName");
      valid = false;
    }
    if (fieldState.areaOfExpertise.trim() === "") {
      error.firstName = t("info.firstName");
      valid = false;
    }
    if (fieldState.licenseNumber.trim() === "") {
      error.lastName = t("info.lastName");
      valid = false;
    }
    if (fieldState.idNumber.trim() === "") {
      error.firstName = t("info.firstName");
      valid = false;
    }

    if (fieldState.phone.trim() === "") {
      error.phone = t("info.phone");
      valid = false;
    }
    if (fieldState.email.trim() === "") {
      error.email = t("info.email");
      valid = false;
    }
    // if (!isChecked) {
    //   error.isCheck = "זה נדרש";
    //   valid = false;
    // }
    // if (!recaptchaToken) {
    //   error.captcha = "Captcha is required";
    //   valid = false;
    // }
    return { valid, error };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setErrors({});

    const contactValidation = validateContactField();

    if (!contactValidation.valid) {
      setErrors({ ...contactValidation.error });
      setLoader(false);
      return;
    }

    try {
      const captchaToken = await executeRecaptcha("newcontactform");
      const response = await axios.post(
        `${serverPath}/public/doctor-registration-request/${captchaToken}`,
        {
          ...fieldState,
        }
      );

      const {
        data: { code },
      } = response || {};

      if (code === 200) {
        handleShow();
        setFormStatus("success");
        setLoader(false);
        setFieldState(contactInit); // Reset the form
        setIsChecked(false);
      }
    } catch (error) {
      setLoader(false);
      setFormStatus("error");
      setRefreshReCaptcha(!refreshReCaptcha);
      handleShow();
    }
  };

  const setTokenFunc = useCallback((getToken) => {
    setRecaptchaToken(getToken);
  }, []);
  console.log("value", recaptchaToken?.slice(0, 10));

  const originData = async () => {
    const res = await axios.get("https://ipapi.co/json");
    setUserGeoData(res.data);
  };
  useEffect(() => {
    originData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="new-contact-form">
        <Form onSubmit={handleSubmit}>
          <div className="form-group-grid">
            <Form.Group className="form-group">
              <Form.Label>{t("Full name in Hebrew")} </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Full name in Hebrew")}
                name="hebName"
                onChange={handleChange}
                value={fieldState.hebName || ""}
                required
              />
              {!!errors?.hebName && (
                <p className="text-danger">{errors?.hebName}</p>
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{t("Full name in English")} </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("Full name in English")}
                name="enName"
                onChange={handleChange}
                value={fieldState.enName || ""}
                required
              />
              {!!errors?.enName && (
                <p className="text-danger">{errors?.enName}</p>
              )}
            </Form.Group>
          </div>
          <Form.Group className="form-group">
            <Form.Label>{t("Area of expertise")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("Area of expertise")}
              name="areaOfExpertise"
              onChange={handleChange}
              value={fieldState.areaOfExpertise || ""}
              required
            />
            {!!errors?.areaOfExpertise && (
              <p className="text-danger">{errors?.areaOfExpertise}</p>
            )}
          </Form.Group>
          <div className="form-group-grid">
            <Form.Group className="form-group">
              <Form.Label>{t("Doctor's license number")} </Form.Label>
              <Form.Control
                type="number"
                placeholder={t("Doctor's license number")}
                name="licenseNumber"
                onChange={handleChange}
                value={fieldState.licenseNumber || ""}
                required
              />
              {!!errors?.licenseNumber && (
                <p className="text-danger">{errors?.licenseNumber}</p>
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{t("ID number (including check digit)")} </Form.Label>
              <Form.Control
                type="text"
                placeholder={t("ID number (including check digit)")}
                name="idNumber"
                onChange={handleChange}
                value={fieldState.idNumber || ""}
                required
              />
              {!!errors?.idNumber && (
                <p className="text-danger">{errors?.idNumber}</p>
              )}
            </Form.Group>
          </div>
          <div className="form-group-grid">
            <Form.Group className="form-group">
              <Form.Label>{t("phone number")} </Form.Label>
              <PhoneInput
                country={userGeoData?.country_code?.toLowerCase() || "il"}
                onChange={handleChangePhone}
                inputProps={{
                  name: "phone",
                  placeholder: t("phone number"),
                }}
                value={fieldState.phone}
              />
              {!!errors?.phone && (
                <p className="text-danger">{errors?.phone}</p>
              )}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>{t("email address")} </Form.Label>
              <Form.Control
                type="email"
                placeholder={t("email address")}
                name="email"
                onChange={handleChange}
                value={fieldState.email || ""}
                required
              />
              {!!errors?.email && (
                <p className="text-danger">{errors?.email}</p>
              )}
            </Form.Group>
          </div>
          <Form.Group className="form-group">
            <Form.Label>{t("How did I hear about HIDOC?")}</Form.Label>
            <Form.Control
              type="text"
              placeholder={t("How did I hear about HIDOC?")}
              name="sourceOfDiscovery"
              onChange={handleChange}
              value={fieldState.sourceOfDiscovery || ""}
            />
            {!!errors?.sourceOfDiscovery && (
              <p className="text-danger">{errors?.sourceOfDiscovery}</p>
            )}
          </Form.Group>

          <Form.Group className="form-group" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label={
                <p
                  dangerouslySetInnerHTML={{
                    __html: t(
                      "I confirm the verification of insurance for remote private practice through Madanes Insurance Agency"
                    ),
                  }}
                />
              }
              name="madanesConsent"
              checked={isChecked}
              onChange={handleIsCheck}
            />
            {!!errors?.isCheck && <p className="text-danger">{"זה נדרש"}</p>}
          </Form.Group>
          <GoogleReCaptcha
            onVerify={setTokenFunc}
            refreshReCaptcha={refreshReCaptcha}
          />
          <Button className="black-btn" type="submit" disabled={loader}>
            {loader ? <Spinner animation="border" /> : "שלח"}
          </Button>
        </Form>
      </div>

      <InfoModal show={show} handleShow={handleShow} formStatus={formStatus} />
    </>
  );
};

export default RxContactForm;
