import { useEffect, useState } from "react";
import Storage from "../../utils/storage";
import NewLayout from "../layout/newLayout";
import RxBanner from "./RxBanner";
import RxContactSection from "./RxContact";
import RxFAQSection from "./RxFaq";
import RxFooter from "./RxFooter";
import RxHeader from "./RxHeader";
import RxHero from "./RxHero";
import RxTestimonial from "./RxTestimonial";
import WhyHidocRx from "./WhyHidocRx";
import "./rx.css";

const RxLanding = () => {
  const [isRtl, setIsRtl] = useState(false);

  useEffect(() => {
    const lang = Storage.get("lang");
    if (lang) {
      setIsRtl(lang.isRTL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Storage.get("lang")]);

  return (
    <NewLayout>
      <div className="rx-detail" style={{ direction: !isRtl ? "ltr" : "rtl" }}>
        <RxHeader />
        <RxHero />
        <RxBanner />
        <WhyHidocRx />
        <RxFAQSection />
        <RxTestimonial />
        <RxContactSection />
        <RxFooter />
      </div>
    </NewLayout>
  );
};

export default RxLanding;
