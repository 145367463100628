import Storage from "../../utils/storage";
import RxContactForm from "./RxContactForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const RxContactSection = () => {
  const lang = Storage.get("lang")?.shortCode
    ? Storage.get("lang")?.shortCode
    : "he";
  return (
    <GoogleReCaptchaProvider
      language={lang === "en" ? "en" : "iw"}
      // reCaptchaKey="6Ldlc04qAAAAAGtz64kd9mFGaBBovx0VZU01r5Dt" // for testing
      reCaptchaKey="6LcGXk4qAAAAAAtflcTFs8A1by6WFEgv39QawkOv" //hidoc key
    >
      <div className="rx-contact-section" id="rx-contact-section">
        <div className="container">
          <div className="rx-contact-content">
            <div className="new-contact-title">
              <h2>השאירו פרטים</h2>
              <p>
                רופא.ה יקר.ה, יש למלא את <span>מלוא הפרטים</span> בטופס הבא,
                לאחר עיבוד הטופס ובמידה ואכן יש רישיון רפואי בתוקף וביטוח אחריות
                מקצועית, יפתח עבורך חשבון במוצר המרשמים וסיכומי הביקור שלנו
                בהיידוק מחברת ואלור מוצרים חכמים בע"מ. הודעה תישלח במעמד פתיחת
                החשבון עם הנחיות כניסה לחשבון. החשבון יפתח לחודש התנסות ללא עלות
                ולאחר מכן המחיר החודשי למנוי הינו 180 שקל (+ מע"מ)
              </p>
            </div>
            <RxContactForm />
          </div>
        </div>
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default RxContactSection;
