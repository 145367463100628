import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { scrollToRxContact } from "../../utils/functions";

const RxHero = () => {
  const wordRef = useRef(null);
  const words = [" יעיל ", " נוח ", " בטוח "];
  const [index, setIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [containerWidth, setContainerWidth] = useState("auto");

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      const timeout = setTimeout(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
        setFade(true);
      }, 100);

      return () => clearTimeout(timeout);
    }, 5000);

    return () => clearInterval(interval);
  }, [words.length]);

  useEffect(() => {
    if (wordRef.current) {
      setContainerWidth(wordRef.current.offsetWidth + 2);
    }
  }, [index, fade]);

  return (
    <div className="rx-hero">
      <div className="container">
        <div className="rx-hero-content">
          <h2>הפיתרון המושלם </h2>
          <h3>לקליניקה הדיגיטלית שלך</h3>
          <h4 className="smooth-heading">
            <span>מרשמים דיגיטליים -</span>{" "}
            <span
              className="dynamic-text-grid"
              style={{
                width: `${containerWidth}px`,
                transition: "width 0.5s ease",
              }}
            >
              {words.map((word, wordIndex) => (
                <span
                  key={wordIndex}
                  ref={wordIndex === index ? wordRef : null}
                  className={`fades ${
                    wordIndex === index && fade ? "fade-in" : "fade-out"
                  }`}
                >
                  {" "}
                  {word}{" "}
                </span>
              ))}
            </span>
            <span className="right-text"> יותר! </span>
          </h4>
          <Button className="black-btn" onClick={scrollToRxContact}>
            להשארת פרטים{" "}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RxHero;
