import { Button } from "react-bootstrap";
import {
  FeaturIcon1,
  FeaturIcon2,
  FeaturIcon3,
  FeaturIcon4,
  FeaturIcon6,
} from "../common/icon";
import FeaturIcon5 from "../../assets/images/new/FeaturIcon5.png";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const OurFeature = () => {
  return (
    <div className="our-feature">
      <div className="container">
        <div className="feature-main-grid">
          <div className="feature-title">
            <h2>
              כל הטוב הזה, <span>תחת מקום אחד</span>
            </h2>
          </div>
          <div className="features-grid">
            <div className="features-box">
              <div className="features-icon">
                <FeaturIcon1 />
              </div>
              <div className="features-text">
                <h3>זמינות</h3>
                <p>
                  אנחנו פועלים במודל Uber, ולכן אנחנו משיגים פגישות ייעוץ עם
                  רופאים בכירים בזמינות ממש גבוהה, לפעמים אפילו מהיום להיום
                </p>
              </div>
            </div>

            <div className="features-box">
              <div className="features-icon">
                <FeaturIcon2 />
              </div>
              <div className="features-text">
                <h3>נוחות</h3>
                <p>
                  השירות שלנו הוא אונליין ופגישת הייעוץ מתבצעת בZoom, לכן ניתן
                  לקיים את השיחה מכל מכשיר, בנוחות של הבית, במשרד או מהים;)
                </p>
              </div>
            </div>

            <div className="features-box">
              <div className="features-icon">
                <FeaturIcon3 />
              </div>
              <div className="features-text">
                <h3>מחיר אטרקטיבי</h3>
                <p>
                  הייעוץ
                  אצלנו עולה 990 ש״ח וניתן לקבל עליו החזר מחברות הביטוח.
                </p>
              </div>
            </div>

            <div className="features-box">
              <div className="features-icon">
                <FeaturIcon4 />
              </div>

              <div className="features-text">
                <h3>מרשם דיגיטלי</h3>
                <p>
                  במידה וניתן מרשם במהלך פגישת הייעוץ, ניתן לאסוף אותו בכל אחד
                  מבתי המרקחת של רשת סופר פארם בהצגת תעודת זהות
                </p>
              </div>
            </div>

            <div className="features-box">
              <div className="features-icon">
                <img src={FeaturIcon5} alt="icon" />
              </div>
              <div className="features-text">
                <h3>העלאת מסמכים</h3>
                <p>
                  צריכים להתייעץ על תוצאות בדיקות או תמונה? צריכים פיענוח לבדיקת
                  דימות? אין בעיה, ניתן להעלות הכל לצפיית הרופא/ה טרם השיחה
                </p>
              </div>
            </div>

            <div className="features-box">
              <div className="features-icon">
                <FeaturIcon6 />
              </div>

              <div className="features-text">
                <h3>סיכום טיפול</h3>
                <p>
                  בסיום פגישת הייעוץ מקבלים מסמך סיכום טיפול חתום דיגיטלית על
                  ידי הרופא/ה. מרשמים נשלחים לסניפי סופרפארם וזמינים לרכישה.
                </p>
              </div>
            </div>
          </div>
          <div className="feature-cta">
            <Button
              className="black-btn"
              onClick={() => {
                handleCheckUrl(`${appURL}/meetingrequest`);
              }}
            >
              שלח/י בקשה לייעוץ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurFeature;
