import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/bootstrap.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactUs from "./components/contact/contact";
import PrivacyPolicy from "./components/TermsPages/privacy";
import AboutUs from "./components/about/about";
import TermsConditions from "./components/TermsPages/terms";
import { serverPath, appURL } from "./config/key";
import Faq from "./components/faq/faq";
import NewHome from "./components/newHome/newHome";
import DoctorDetail from "./components/doctor";
import ConsultingDetail from "./components/consulting";
import AppContext from "./context/appContext";
import PharmaciesList from "./components/yarpa/pharmaciesList.jsx";
import RxLanding from "./components/RxLanding/index.jsx";
const App = () => {
  const [singleConsulting, setSingleConsulting] = useState();
  useEffect(() => {
    if (window.location.pathname === "/") {
      let ele = document.getElementById(window.location.hash.substring(1));
      if (ele) {
        ele.scrollIntoView({ top: 0, behavior: "smooth" });
      }
    }
  }, []);
  console.log("serverPath & appURL", serverPath, appURL);
  return (
    <div className="App">
      <AppContext.Provider value={{ singleConsulting, setSingleConsulting }}>
        <Routes>
          <Route exact path="/" element={<NewHome />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/terms" element={<TermsConditions />} />
          <Route exact path="/faq_clients" element={<Faq />} />
          <Route exact path="/:expertise" element={<NewHome />} />
          <Route exact path="/detail-doctor" element={<DoctorDetail />} />
          <Route
            exact
            path="/consulting-detail/"
            element={<ConsultingDetail />}
          />
           <Route exact path="/yarpa-pharmacies" element={<PharmaciesList />} />
           <Route exact path="/rx" element={<RxLanding />} />
        </Routes>
      </AppContext.Provider>
    </div>
  );
};

export default App;
