import RxFaqList from "./RxFaqList";

const RxFAQSection = () => {
  return (
    <div className="rx-faq">
      <div className="container">
        <div className="rx-faq-content">
          <div className="new-faq-title">
            <h2>שאלות ותשובות</h2>
          </div>
          <RxFaqList />
        </div>
      </div>
    </div>
  );
};

export default RxFAQSection;
