const RxFooter = () => {
  return (
    <>
      <div className="rx-footer">
        <div className="container">
          <div className="rx-footer-content">
            <h2>Powered by Madanes</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default RxFooter;
