import { CommaOne, CommaTwo } from "../common/icon";
import RxTestimonialSlider from "./RxTestimonialSlider";

const RxTestimonial = () => {
  return (
    <div className="rx-testimonial-section">
      <div className="container">
        <div className="rx-testimonial-content">
          <div className="testimonial-title">
            <h2>רופאים שאוהבים אותנו</h2>
          </div>
          <div className="comma-one">
            <CommaOne />
          </div>
          <RxTestimonialSlider />
          <div className="comma-two">
            <CommaTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RxTestimonial;
