import icon1 from "../../assets/images/rx/hand-with-heart.png";
import icon2 from "../../assets/images/rx/box.png";
import icon3 from "../../assets/images/rx/hand-with-mobile.png";
import icon4 from "../../assets/images/rx/hand-with-tab.png";
import icon5 from "../../assets/images/rx/hand-with-clock.png";
import { Button } from "react-bootstrap";
import { scrollToRxContact } from "../../utils/functions";

const WhyHidocRx = () => {
  return (
    <div className="why-rx">
      <div className="container">
        <div className="why-rx-content">
          <h2>למה HidocRX?</h2>
          <div className="why-rx-grid">
            <div className="why-rx-box">
              <div className="why-rx-icon">
                <img src={icon1} alt="icon1" />
              </div>
              <h3>שירות פרימיום למטופלים</h3>
              <p>
                העניקו למטופלים שלכם שירות מתקדם יותר, עם גישה נוחה ומהירה
                לתרופות שלהם. הם יודו לכם!
              </p>
            </div>
            <div className="why-rx-box">
              <div className="why-rx-icon">
                <img src={icon2} alt="icon2" />
              </div>
              <h3>תיעוד מאורגן</h3>
              <p>
                שמרו את כל המרשמים והסיכומים במקום אחד, דיגיטלי והיפרדו לשלום
                מהניירת (עם בונוס של שמירה על הסביבה!)
              </p>
            </div>
            <div className="why-rx-box">
              <div className="why-rx-icon">
                <img src={icon3} alt="icon3" />
              </div>
              <h3>אבטחת מידע ללא דאגות</h3>
              <p>
                נתוני המטופלים שלכם מוגנים במערכת מאובטחת בעלת סטנדרטים גבוהים.
                שקט נפשי מובטח!
              </p>
            </div>
            <div className="why-rx-box">
              <div className="why-rx-icon">
                <img src={icon4} alt="icon4" />
              </div>
              <h3>חופש מקצועי</h3>
              <p>
                תהנו מגמישות מקסימלית ומהיכולת לרשום למטופלים שלכם מרשמים ו/או
                סיכומי ביקור מכל מקום ובכל מכשיר
              </p>
            </div>
            <div className="why-rx-box">
              <div className="why-rx-icon">
                <img src={icon5} alt="icon5" />
              </div>
              <h3>חסכון משמעותי בזמן</h3>
              <p>
                שליחת מרשמים מרחוק יכולה לחסוך זמן יקר של הגעה לקליניקה הן לרופא
                והן למטופלים הזקוקים למרשם תקופתי
              </p>
            </div>
          </div>
          <div>
            <Button className="black-btn" onClick={scrollToRxContact}>
              להשארת פרטים{" "}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHidocRx;
