import heart from "../../assets/images/rx/heart.png";

const RxBanner = () => {
  return (
    <div className="rx-banner">
      <div className="container">
        <div className="rx-banner-content">
          <h2>חוויה משופרת לרופאים ומטופלים</h2>
          <p>
            HidocRX הוא פתרון המאפשר לך לרשום מרשמים בצורה <span>פשוטה</span>,{" "}
            <span>יעילה</span> ו<span>מאובטחת</span> יותר. איתנו המרשמים
            הדיגיטליים שלך ישלחו באופן אוטומטי לרוב בתי המרקחת בארץ!
          </p>
          <div className="heart-logo">
            <img src={heart} alt="heart" width={800} height={877} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RxBanner;
