import Slider from "react-slick";

const RxTestimonialSlider = () => {
  const settings = {
    dots: true,
    cssEase: "linear",
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          autoplay: true,
        },
      },
      {
        breakpoint: 769,
        settings: {
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          autoplay: true,
        },
      },
    ],
  };
  const ourExpertList = [
    {
      id: 0,
      name: 'ד"ר ליאונטי גרין',
      category: "גניקולוגיה",
      feedback:
        "אני מאוד מרוצה מהשימוש באפליקציית היידוק. מאפשרת לשלוח מרשמים למטופלות בכל הארץ ללא צורך במרשם ידני. אפליקציה נוחה לשימוש, תמיכה טכנית זמינה מאוד ויעילה. ממליץ בחום.",
    },
    {
      id: 1,
      name: 'ד"ר ליאור דבוראק',
      category: "פסיכיאטריה",
      feedback:
        "היידוק היא מערכת נוחה (מאוד!) למרשמים דיגיטליים. הפכה את העבודה שלי להרבה יותר פשוטה, בלי להריץ את המטופלים למרפאה, או אפילו לקום מהכיסא. ואם עלו בעיות, שירות התמיכה הוא מעולה. מומלצת בחום!",
    },
    {
      id: 2,
      name: "ד״ר רות ברוך מובשוביץ",
      category: "פסיכיאטריה",
      feedback:
        "כפסיכיאטרית בקליניקה הפרטית חיכיתי שנים בציפייה להקמתו של ממשק דיגיטלי כמו HIDOC. בחודשיים האחרונים הוא משמש אותי באופן יום-יומי וחוסך למטופלים הגעה לקליניקה לאיסוף מרשמים ומאפשר לי גמישות במתן השירות מכל מקום בו אני נמצאת (לצערי טרם הספקתי להתנסות בשליחה מחו״ל). גיליתי שצוות Hidoc זמין מאד ומלווה בצמוד את הטמעת המערכת החדשה ונותן מענה גם עבורי וגם ישירות למטופלים שמתקשים לעיתים לקבל מענה מרוקחים מסויימים בסופרפארם שטרם מכירים את החידוש.",
    },
    {
      id: 3,
      name: "ד״ר טל להב",
      category: "פסיכיאטריה",
      feedback:
        "המערכת של היידוק לגמרי שינתה את אופן העבודה שלי בקליניקה הפרטית. האפשרות לשלוח מרשם מכל מקום ובכל זמן, תוך שניות, באופן פרטי ומאובטח - חוסכת כאב ראש וזמן יקר. העבודה הפכה הרבה יותר אלגנטית ויעילה, המטופלים מרוצים. תודה להיידוק!",
    },
  ];

  return (
    <div className="container">
      <div className="rx-slider-grid">
        <Slider {...settings}>
          {ourExpertList.map((el) => (
            <div className="rx-slide" key={el?.id}>
              <div className="rx-slide-content">
                <h6>{el.feedback}</h6>
              </div>
              <div className="rx-slide-footer">
                <h6>{el.name}</h6>
                <p>{el.category}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default RxTestimonialSlider;
