/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Storage from "../../utils/storage.js";
import en from "../../assets/images/icons/en.svg";
import he from "../../assets/images/icons/he.svg";
import Loader from "../Loader/loader.jsx";
import NewHeader from "../Header/newHeader.jsx";
import NewFooter from "../Footer/newFooter.jsx";
import SupportChat from "../supportChat/supportChat.jsx";
import { useLocation } from "react-router-dom";

const NewLayout = ({ children }) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const [currLang, setCurrLang] = useState(null);
  const [loading, setLoading] = useState(false);
  const allLanguages = [
    {
      id: 1,
      name: "עברית",
      shortCode: "he",
      apiShortCode: "heb",
      flag: he,
      isRTL: true,
    },
    {
      id: 2,
      name: "English",
      shortCode: "en",
      apiShortCode: "eng",
      flag: en,
      isRTL: false,
    },
  ];

  const handleChangeLanguage = (language) => {
    setLoading(true);
    Storage.set("lang", language);
    window.location.reload();
    setLoading(false);
  };

  const handleCheckLang = () => {
    const currentLanguage = Storage.get("lang");
    if (currentLanguage) {
      setCurrLang(currentLanguage);
    } else {
      Storage.set("lang", allLanguages[0]);
      setCurrLang(allLanguages[0]);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    handleCheckLang();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (Storage.get("lang").shortCode) {
      i18n.changeLanguage(Storage.get("lang").shortCode);
    }
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div
      className={`new-main-layout ${
        Storage.get("lang")?.isRTL ? "rtl" : "ltr"
      }`}
      lang={Storage.get("lang")?.shortCode}
    >
      {!location.pathname.includes("/rx") && (
        <NewHeader
          allLanguages={allLanguages}
          handleChangeLanguage={handleChangeLanguage}
          currLang={currLang}
        />
      )}
      <div className="content">{children}</div>
      {!location.pathname.includes("/rx") && (
        <>
          <NewFooter />
          <SupportChat />
        </>
      )}
    </div>
  );
};

export default NewLayout;
