import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form } from "react-bootstrap";
import "./yarpa.css";
import NewLayout from "../layout/newLayout";
import PharmaciesListData from "../../components/yarpa/pharmaciesList.json";
import search from "../../assets/images/new/search.svg";
import searchIconSm from "../../assets/images/new/search-icon-sm.svg";
import Storage from "../../utils/storage.js";

const PharmaciesList = () => {
  const { t } = useTranslation();
  const lang = Storage.get("lang");
  const PharmaciesListArray = PharmaciesListData.pharmacieslist;
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setFilteredData(PharmaciesListArray);
  }, [PharmaciesListArray]);

  const handleSearch = (e) => {
    const searchWord = e.target.value.toLowerCase();
    setSearchText(searchWord);

    if (searchWord === "") {
      setFilteredData(PharmaciesListArray);
    } else {
      const newFilter = PharmaciesListArray?.filter(
        (pharmacy) =>
          pharmacy?.name?.toLowerCase().includes(searchWord) ||
          pharmacy?.city?.toLowerCase().includes(searchWord) ||
          pharmacy?.address?.toLowerCase().includes(searchWord) ||
          pharmacy?.phone?.toLowerCase().includes(searchWord)
      );
      setFilteredData(newFilter);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <NewLayout>
      <div className="pharmacies-list-page">
        <div className="container">
          <div className="pharmacies-list-title">
            <h2>{t("Yarpa pharmacies")}</h2>
          </div>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="search-input-form">
              <Form.Control
                type="search"
                placeholder={t("Pharmacies...")}
                value={searchText}
                onChange={handleSearch}
                required
              />
              <img
                src={search}
                alt="search"
                className="search-icon"
                width={24}
                height={24}
              />
              <Button className="black-btn" type="submit">
                <img src={searchIconSm} alt="search" width={18} height={18} />
              </Button>
            </Form.Group>
          </Form>
          <div
            className="pharmacies-list-content"
            style={{ direction: lang?.isRTL ? "rtl" : "ltr" }}
          >
            <div className="custom-head">
              <div className="custom-row">
                <h6>{t("Name")} </h6>
                <h6>{t("City")} </h6>
                <h6>{t("Address")} </h6>
                <h6>{t("Phone")} </h6>
              </div>
            </div>

            <div
              className="custom-body"
              style={{
                alignItems: filteredData?.length > 0 ? "unset" : "center",
              }}
            >
              {filteredData && filteredData?.length > 0 ? (
                filteredData.map((el, i) => (
                  <div className="custom-row" key={i}>
                    <h6>{el?.name || "--"} </h6>
                    <h6>{el?.city || "--"} </h6>
                    <h6>{el?.address || "--"} </h6>
                    <h6>{el?.phone || "--"} </h6>
                  </div>
                ))
              ) : (
                <h6>{t("no result")}</h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </NewLayout>
  );
};

export default PharmaciesList;
