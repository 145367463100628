import { Button } from "react-bootstrap";
import { StarIcon } from "../common/icon";
import { handleCheckUrl } from "../../utils/functions";
import { appURL } from "../../config/key";

const Testimonial = () => {
  return (
    <div className="testimonial-section">
      <div className="container">
        <div className="testimonial-title">
          <h2>הם יודעים מה הם אומרים</h2>
        </div>
        <div className="testimonial-grid">
          <div className="testimonial-sub-grid">
            <div className="testimonial-box mobile-view-hide first-line">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
              <h6>
                  ״התור בהיידוק היה ממש הצלה, לא הצלחנו להשיג תור בהקדם למומחה
                  ובהיידוק תוך כמה שעות כבר פגשנו את ד״ר גלית ישראלי, קיבלנו
                  הבחנה ומרשם במקום לחכות ולשלם יותר.״
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>ענבל נוימן, תל אביב</h6>
                <p>אנדוקרינולוגיה ילדים</p>
              </div>
            </div>

            <div className="testimonial-box mobile-view-hide second-line">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>“שירות מושלם לא מבינה איך לא שמעתי עליו קודם”</h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>גסטרואנטרולוגיה</p>
              </div>
            </div>
          </div>

          <div className="testimonial-sub-grid">
            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  ״היינו צריכים רופא מומחה שדובר רוסית ומצאנו את הפרטים באתר
                  שלכם. אמא שלי לא יכולה להגיע לארץ בגלל המצב ורצינו רופא מישראל
                  שידבר איתה וימליץ מה לעשות. לייעוץ מאיכילוב הגענו דרך היידוק
                  ובדקנו המלצות באתרים אחרים לגבי הרופא הזה. תיאמנו שיחה לשישי
                  בערב כדי שלאמא יהיה נוח לדבר ואכן החוויה הייתה טובה מאוד.
                  הרופא הקשיב, הסביר והייתה לו סבלנות רבה. לאחר השיחה קיבלנו
                  המלצות מה צריך לעשות הלאה כולל בדיקות. הרופא אמר שאם יהיו לנו
                  שאלות נוספות, נוכל לפנות בכל שאלה. תודה רבה לכם על השירות
                  המצויין"
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>טניה פישמן, קרית גת</h6>
                <p>רפואה פנימית</p>
              </div>
            </div>
            <div className="testimonial-box">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
              <h6>
                  “חווית השימוש שלי נהדרת. מענה תוך זמן אפס ובכל שעה, זמינות תור
                  מיידית וההתקשרות עם ד"ר ישראלי הייתה לי נעימה אינפורמטיבית
                  ועניינית. מערכת התמיכה של האתר נפלאה עד כדי פליאה. ממש כיף
                  לדעת שיש אכן שירות לקוחות שבאמת עוזר!! זה מצרך נדיר בימינו אלו
                  תמשיכו כך! תודה ללור שעזרה לי מאוד בהעברת הקבצים לרופאה כדי
                  שהפגישה תהיה יעילה”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>אנדוקרינולוגיה</p>
              </div>
            </div>
          </div>
          <div className="testimonial-sub-grid tab-view-hide">
            <div className="testimonial-box first-line">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  ״אני פחות סומכת על המערכת הרפואית בפורטוגל ורציתי חוות דעת
                  מרופא ישראלי, קיבלתי מענה מהיר ויעיל כמו שקיוויתי והייתי צריכה
                  מרופאת עור מקסימה ומקצועית. היכולת לדבר מהר עם רופא בעברית
                  הייתה משמעותית מאוד במקרה שלנו.״
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>תמר לביא בלייוייס, פורטוגל</h6>
                <p>רפואת ילדים</p>
              </div>
            </div>

            <div className="testimonial-box mobile-view-hide second-line">
              <div className="star-grid">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
              <div className="testimonial-text">
                <h6>
                  “אני חייבת לפרגן לכם ולהגיד שהייתי צריכה תור מהרגע להרגע ופשוט
                  הצלתם אותי! איזה שירות מטורף! כל הכבוד לכם!!!”
                </h6>
              </div>
              <div className="testimonial-name">
                <h6>לקוח מרוצה</h6>
                <p>גניקולוגיה</p>
              </div>
            </div>
          </div>
        </div>
        <div className="feature-cta">
          <Button
            className="black-btn"
            onClick={() => {
              handleCheckUrl(`${appURL}/meetingrequest`);
            }}
          >
            שלח/י בקשה לייעוץ
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
