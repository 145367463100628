import RxLogo from "../../assets/images/rx/Rx-Logo.png";

const RxHeader = () => {
  return (
    <div className="rx-header">
      <div className="container">
        <div className="rx-logo">
          <img src={RxLogo} alt="Hidoc RX" width={800} height={293} />
        </div>
      </div>
    </div>
  );
};

export default RxHeader;
